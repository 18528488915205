import Image from '../../modules/image';
import PreTitle from '../../modules/pretitle';
import Title from '../../modules/title';
import Text from '../../modules/text';
import './main-boxes.scss';

type MainBoxesProps = {
    preTitle?: string,
    title?: string,
    items: Array<MainBoxesItemProps>,
    onItemClick?: (title?: string) => void
}

type MainBoxesItemProps = {
    image?: string,
    title: string,
    text?: string,
}

const MainBoxes = (props: MainBoxesProps) => {
    return (
        <section className="main-boxes">
            <div className="container">
                <PreTitle>
                    {props.preTitle}
                </PreTitle>
                <Title size={1}>
                    {props.title}
                </Title>
                <div className="boxes">
                    {props.items.map((item, index) => (
                        <div key={"main-boxes-" + index} className="box">
                            <Image key={"main-boxes-image-" + index} src={item.image}></Image>
                            <div onClick={() => props.onItemClick && props.onItemClick(item.title)} >
                                <Title key={"main-boxes-title-" + index} size={2}>
                                    {item.title}
                                </Title>
                                <Text key={"main-boxes-text-" + index} size={1}>
                                    {item.text}
                                </Text>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default MainBoxes;