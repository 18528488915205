import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {logout} from "../../../actions/authenticationActions";
import {alca} from "../../../alca/alca";
import logo from '../../../assets/Icons/logo.svg';
import {getUserInfo} from "../../../store/globalSlice";
import {useAppSelector} from "../../../store/hooks";
import Image from "../../modules/image";
import './header.scss';

const Header = () => {
    const [menu, setMenu] = useState(false);
    const userInfo = useAppSelector(getUserInfo);

    useEffect(() => {
        alca.scroll.lock(menu);
    }, [menu]);

    const toggleMenu = (event: React.MouseEvent) => {
        setMenu(!menu);
    }

    const logoutClick = (event: React.MouseEvent) => {
        logout();
    }

    return (
        <header>
            <div className="container">
                <Link to="/">
                    <img className="logo" src={logo} alt=""/>
                </Link>
                <button className="btn-menu mobile-only" type="button" onClick={toggleMenu}>
                    {menu ?
                        <Image src={require("../../../assets/Icons/close.svg").default}/>
                        :
                        <Image src={require("../../../assets/Icons/menu.svg").default}/>
                    }
                </button>
                <div className={"menu-container" + (menu ? " active" : "")} onClick={() => setMenu(false)}>
                    <div className="menu">
                        <Link className="button s2" to="/about">Hakkımızda</Link>
                        <Link className="button s2" to="/contact">Bize Ulaşın</Link>
                        <Link className="button s2" to="/corporate-governance">Kurumsal Yönetim</Link>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header;