import React from 'react';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Box,
    Link,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface CustomAccordionProps {
    title: string;
    titleDetail: string;
    content: string;
    links?: { text: string; url: string }[];
    photo?: string;
    graph?: string;
}

const CustomAccordion: React.FC<CustomAccordionProps> = ({ title, content, titleDetail, links, photo, graph }) => {
    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: '#e63946' }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography
                    variant="h6"
                    style={{ fontWeight: 400, color: '#e63946' }}
                    sx={{ width: { xs: '100%', sm: '33%' }, flexShrink: 0 }}
                >
                    {title}
                </Typography>
                <Typography sx={{ color: 'text.secondary', width: { xs: '100%', sm: '67%' } }}>{titleDetail}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        gap: '16px',
                        width: '100%',
                    }}
                >
                    {photo && (
                        <Box
                            component="img"
                            src={photo}
                            alt={`${title} visual`}
                            sx={{
                                width: { xs: '80%', sm: '400px' },
                                height: '350px',
                                objectFit: 'contain',
                                borderRadius: '4px',
                                backgroundColor: '#ffffff',
                                marginBottom: '16px',
                            }}
                        />
                    )}
                    <Box sx={{ flex: 1, width: '100%' }}>
                        <Typography sx={{ textAlign: 'justify', marginBottom: '16px' }}>{content}</Typography>
                        {links && (
                            <Box>
                                {links.map((link, index) => (
                                    <Typography key={index} sx={{ textAlign: 'justify', marginBottom: '8px' }}>
                                        {link.text}{' '}
                                        <Link href={link.url} underline="hover" sx={{ fontWeight: 'bold' }}>
                                            tıklayınız.
                                        </Link>
                                    </Typography>
                                ))}
                            </Box>
                        )}
                    </Box>
                    {graph && (
                        <Box
                            component="img"
                            src={graph}
                            alt={`${title} graph`}
                            sx={{
                                width: { xs: '100%', sm: '80%', md: '60%' },
                                height: 'auto',
                                objectFit: 'contain',
                                borderRadius: '8px',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                backgroundColor: '#ffffff',
                                margin: '16px auto',
                            }}
                        />
                    )}
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

interface TextAccordionItem {
    title: string;
    detail: string;
}

interface CustomTextAccordionProps {
    items: TextAccordionItem[];
    mainTitle: string;
}

const CustomTextAccordion: React.FC<CustomTextAccordionProps> = ({ items, mainTitle }) => {
    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: '#e63946' }} />}
                aria-controls="panel1b-content"
                id="panel1b-header"
            >
                <Typography
                    variant="h6"
                    style={{ fontWeight: 400, color: '#e63946' }}
                >
                    {mainTitle}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Box sx={{ width: '100%' }}>
                    {items.map((item, index) => (
                        <Box key={index} sx={{ marginBottom: '16px' }}>
                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', marginBottom: '8px' }}>
                                {item.title}
                            </Typography>
                            <Typography sx={{ textAlign: 'justify' }}>{item.detail}</Typography>
                        </Box>
                    ))}
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

export { CustomAccordion, CustomTextAccordion };
